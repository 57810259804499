.col_custom_form > .field {
  margin-bottom: 1em !important;
}

.container_custom_loader {
  position: fixed;
  text-align: center;
  z-index: 9999;
  background-color: #eeeeee;
  top: 0;
  width: 100%;
  left: 0;
  height: 100vh;
}

#custom_slider {
  position: fixed;
  background-size: cover;
  overflow-y: scroll;
  padding: 15px;
  background-color: white;
}

#custom_slider,
.custom_overlay {
  top: 60px;
  height: 93.8vh;
  right: 0;
  z-index: 9;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.custom_overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  position: fixed;
  left: 0px;
}

.container_img {
  text-align: center;
  width: 100%;
}

.container_doc {
  text-align: center;
  width: 80%;
  margin-top: 13px;
  position: absolute;
}

.ui.red.icon.button{
  background-color: #db2828 !important;
}

.ui.red.button:hover{
  background-color: #d01919 !important;
}

.container_image_preview {
  display: inline-block;
}

/* .container_image_preview > video {
  margin-top: 60px;
} */

.button-adjuntar > .ui.teal.button{
  background-color: #00b5ad !important;
}
.button-adjuntar > .ui.teal.button:hover{
  background-color: #009c95 !important;
}

#img_user {
  width: 291px;
  height: 320px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: -webkit-center;
}

#img_detail {
  width: 120px;
  height: 120px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.ui.card>.image, .ui.cards>.card>.image{
  background-color: #55fade !important;
}
.ui.card>.image>img, .ui.cards>.card>.image>img{
  height: 320px !important;
}
