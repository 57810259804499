@import "./custom_components.css";
html {
  background-color: #f6f3fd;
  font-family: "gotham_prolight" !important;
  font-weight: 600;
}

body {
  background-size: cover !important;
  height: 80% !important;
  background-color: transparent !important;
  font-family: "gotham_prolight" !important;
  font-weight: 600;
  line-height: 1.4285vw !important;
}

@font-face {
  font-family: "poppinsbold";
  src: url("Fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("Fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinslight";
  src: url("Fonts/poppins-light-webfont.woff2") format("woff2"),
    url("Fonts/poppins-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("Fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("Fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "quicksandlight";
  src: url("Fonts/quicksand-variablefont_wght-webfont.woff2") format("woff2"),
    url("Fonts/quicksand-variablefont_wght-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gotham_prolight";
  src: url("Fonts/GothamPro.woff2") format("woff2"),
    url("Fonts/GothamPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GothamPro";
  src: url("Fonts/GothamPro-Black.woff2") format("woff2"),
    url("Fonts/GothamPro-Black.woff") format("woff");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "bpreplayregular";
  src: url("Fonts/bpreplay-webfont.woff2") format("woff2"),
    url("Fonts/bpreplay-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0.5rem !important;
  font-family: "gotham_prolight" !important;
  margin-bottom: 0.5rem !important;
  font-weight: 700 !important;
}
textarea {
  font-family: "quicksandlight" !important;
  font-weight: 600;
  padding: 0.5vw;
}
input[type="text"] {
  font-family: "quicksandlight" !important;
  border-radius: 0.3vw !important;
  height: 2.5vw !important;
  font-size: 1vw !important;
  font-weight: 600;
}
input[type="tel"],
input[type="number"] {
  font-family: "quicksandlight" !important;
  height: 2.5vw !important;
  border-radius: 0.3vw !important;
  font-size: 1vw !important;
  font-weight: 600;
}
input[type="date"] {
  font-family: "quicksandlight" !important;
  height: 2.5vw !important;
  border-radius: 0.3vw !important;
  font-size: 1vw !important;
  font-weight: 600;
}
input[type="password"] {
  font-family: "quicksandlight" !important;
  height: 2.5vw !important;
  border-radius: 0.3vw !important;
  font-size: 1vw !important;
  font-weight: 600;
}

i.icon,
i.icons {
  font-size: 1vw !important;
}
.ui.icon.input button.ui.button {
  columns: white !important;
}
.ui.buttons .or {
  font-size: 1vw !important;
}

.ui.label,
.ui.labels .label {
  font-size: 0.8vw !important;
}
.ui.fixed.menu,
.ui[class*="top fixed"].menu {
  height: 6vw !important;
}
.ui.container.todoContenido {
  position: absolute !important;
  background-color: #f6f3fd;
  padding-top: 7.5vw !important;
  z-index: 1 !important;
  width: 100% !important;
  min-height: 74vh !important;
  padding-left: 5vw;
  padding-right: 5vw;
}
.container_wizard label {
  font-size: 1vw !important;
}
.ui.fitted.toggle.checkbox {
  margin-left: 0vw !important;
  width: 2vw !important;
  margin-top: 0.5vw !important;
  margin-bottom: 0.5vw !important;
}
.ui.toggle.checkbox input {
  width: 3.5vw !important;
  height: 1.5vw !important;
}
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #55fade !important;
  width: 3.5vw !important;
  height: 1.5vw !important;
}
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  width: 1.5vw !important;
  height: 1.5vw !important;
}
/*Estilos Login*/
.login_container {
  padding: 2%;
  width: 50%;
}
h1.ui.header {
  font-family: "GothamPro" !important;
  font-weight: 800 !important;
  font-size: 3vw !important;
  padding-right: 20vw !important;
  padding-left: 20vw !important;
  margin-top: 7vw !important;
  margin-bottom: 1vw !important;
}
i.azulI.icon {
  color: #2f62fb !important;
}
.header.headerModales {
  color: white !important;
  text-transform: uppercase !important;
  justify-content: space-between !important;
  font-weight: 700 !important;
  display: flex !important;
}

h1.ui.black.center.aligned.header.headerRegistro {
  margin-top: -1% !important;
  margin-bottom: 1% !important;
}

.column.gridIzq {
  background-size: cover;
  padding: 0% !important;
}
.ui.stacked.segment.login input[type="text"],
.ui.stacked.segment.login input[type="password"] {
  border-right: 0px !important;
  border-left: 0px !important;
  border-top: 0px !important;
  border-bottom: 1px solid #909090 !important;
}

/* .fondoInicio {
  background-image: url('../images/fondoLogin3.png');
  background-size: cover;
  padding: 0% !important;
} */

.column.gridDer {
  /* box-shadow: -1px 0 20px rgba(0, 0, 0, 2.08); */
  padding-right: 2rem !important;
}

.blue-overlay {
  background-image: url("../images/FondoLogin.png");
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-color: #1b396a;
  opacity: 20;
  width: 100%;
  height: 100%;
  opacity: 80%; */
}

.logo_login {
  padding: 5vw 2vw 0vw 3vw;
}
/* .logo_login2 {
  padding: 10% 15% 0% 15%;
} */

.ui.header {
  font-size: 2rem;
  text-transform: uppercase !important;
}

.ui.button {
  font-family: "quicksandlight" !important;
  background-color: #2f62fb !important;
  font-weight: 600 !important;
  height: 2.5vw;
  font-size: 1vw !important;
  padding: 0.5vw 1vw 0.5vw !important;
}
.ui.selection.dropdown .menu > .item[role="option"] {
  padding: 0.78571429vw 1.14285714vw !important;
  font-size: 1vw;
}
.ui.horizontal.list > .item {
  margin-left: 1vw !important;
}
.ui.horizontal.list > .item.tarjeta-plan-general {
  margin: 0px !important;
}
.ui.horizontal.list > .item.tarjeta-plan-finanzas:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.ui.horizontal.list .list > .item,
.ui.horizontal.list > .item {
  font-size: 1vw !important;
}
.ui.horizontal.list {
  margin-top: 5vw !important;
}
.ui.horizontal.list .ui.list.lista-tarjeta-planes .item {
  font-size: 0.9vw !important;
}
.ui.horizontal.list .ui.list.lista-tarjeta-planes .item:hover {
  opacity: 1;
}
tr.tabla-ventas:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.todoContenido .ui.horizontal.list {
  margin-top: 0vw !important;
}
.ui.stacked.segment.login {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding-left: 21vw;
  padding-right: 21vw;
  padding-top: 0%;
}
.ui.stacked.segment.registro {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 0%;
}
.ui.stacked.segment.login::after {
  display: none;
}
.ui.stacked.segment.registro::after {
  display: none;
}

.container_bottom_buttons {
  right: 0;
  width: 100%;
  padding-top: 1.5vw;
  padding-bottom: 1vw;
}
.ui.buttons.login {
  width: 100%;
}
.ui.button:hover {
  background-color: #1c43b8 !important;
  transition-property: opacity, left;
  transition-duration: 3s, 5s;
  /* color: black !important; */
}

.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  color: white !important;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hvr-sweep-to-right-105 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  color: white !important;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: fit-content;
}
.hvr-sweep-to-right-105:hover,
.hvr-sweep-to-right-105:focus,
.hvr-sweep-to-right-105:active {
  color: black !important;
  font-family: "GothamPro" !important;
  font-weight: 600 !important;
}
.hvr-sweep-to-right-105:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  color: black !important;
}
.hvr-sweep-to-right-105:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #55fade;
  color: black !important;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover,
.hvr-sweep-to-right:focus,
.hvr-sweep-to-right:active {
  color: black !important;
  font-family: "GothamPro" !important;
  font-weight: 600 !important;
}
.hvr-sweep-to-right:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  color: black !important;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #55fade;
  color: black !important;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

button.ui.button.hvr-shutter-in-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  font-weight: 700 !important;
  background: #55fade !important;
  color: white !important;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
button.ui.button.hvr-shutter-in-vertical:hover {
  color: black !important;
  font-family: "GothamPro" !important;
  transition: 0.3s;
}

button.ui.button.hvr-shutter-in-vertical:hover:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

button.ui.button.hvr-shutter-in-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1c43b8;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.ui.table {
  background: none !important;
  font-size: 0.8vw !important;
  border: none !important;
}

.ui.table thead tr:first-child > th:first-child {
  border-radius: 1.5em 0 0 0 !important;
}
.ui.table thead tr:last-child > th:last-child {
  border-radius: 0 1.5em 0 0 !important;
}

.ui.message.login {
  background-color: transparent !important;
  box-shadow: none !important;
  text-align: center !important;
  font-size: 1vw !important;
  text-transform: uppercase;
  font-weight: 600;
}

.login_footer {
  position: absolute;
  font-size: 1vw;
  bottom: 0%;
  background-color: #1c43b8;
  width: 100%;
  color: white;
  text-align: center;
  left: -0.1%;
  padding-top: 0.5%;
  padding-bottom: 2.5%;
  height: 3%;
  padding-left: 1%;
}

img.logoInter {
  width: 1.5%;
  vertical-align: text-top;
}

a.pass-forget {
  color: #4e7aff;
  transition: 0.3s;
  font-weight: 600 !important;
}
a.pass-forget:hover {
  color: #55fade;
  background-color: black;
  padding: 0.5%;
  transition: 0.3s;
}
.btnRegresarInicio {
  font-size: 1.2vw;
  width: fit-content;
  transition: 0.3s;
}
.btnRegresarInicio i {
  font-size: 1.2vw !important;
}

.btnRegresarInicio:hover {
  font-size: 1.4vw;
  padding: 0.5%;
  cursor: pointer;
  transition: 0.3s;
  background-color: #55fade;
}

/* @media screen and (max-width: 1366px) {
  .logo_login {
    padding: 12% 5% 0% 5%;
  }

  img.logoInter {
    width: 2%;
  }
  .login_footer {
    height: 4%;
  }
  .ui.stacked.segment.login {
    padding-top: 0%;
  }
} */
/*Fin Estilos Login*/

.ui.modal > .header {
  background: linear-gradient(to left, #4e7aff, #1c43b8) !important;
}
h1.tituloModal {
  font-weight: 700 !important;
}

h3.nombreApartado {
  font-weight: 700 !important;
  text-transform: uppercase;
  margin-top: 1% !important;
  color: #2f62fb;
}

.ui.selection.dropdown > .dropdown.icon {
  padding: 0.4em !important;
}

.ui.selection.dropdown {
  min-width: 10vw !important;
  padding: 0.78571429vw 2.1vw 0.78571429vw 1vw !important;
  /* line-height: 1.6vw !important; */
  height: 2.5vw;
  min-height: fit-content !important;
}

.ui.form .fields {
  margin: 0 -0.5em 3em !important;
}

.ui.center.aligned.middle.aligned.grid.contras {
  background-size: cover;
  top: -5%;
}

.right {
  float: right;
}

.ui.grid {
  margin-top: 0% !important;
  margin-bottom: -2rem !important;
}
.ui.container {
  width: 90% !important;
}

.ui.input {
  width: 100%;
  font-size: 1vw !important;
}
.ui.icon.input.conSwitch {
  width: 60%;
  font-size: 1vw !important;
}

.ui.checkbox {
  margin-left: 10px;
  vertical-align: middle !important;
}

.ui.form .disabled.field {
  opacity: 0.9 !important;
}

.ui.menu > .ui.container {
  margin: 0% !important;
}

.ui.inverted.menu {
  border: 0 solid transparent;
  background-image: url("../images/banner.png") !important;
  background-size: cover !important;
  box-shadow: none;
  font-size: 1vw;
}

.ejemploFilaFechas {
  position: absolute;
  right: 5vw;
  top: 10.8vw;
  z-index: 10;
}

input#buscador {
  /* font-family: 'quicksandlight'; */
  border-radius: 0%;
  height: 2.5vw;
  border: none;
  font-size: 1vw;
}

.title {
  margin-left: 0% !important;
  font-size: 2vw !important;
}
.sub-title {
  font-size: 1.3vw !important;
}

.ui.gray.button {
  background-color: darkgray !important;
}
.ui.stacked.segment.idc {
  background: transparent;
  padding-left: 3%;
  padding-right: 3%;
  box-shadow: none;
  border: none;
}
.ui.stacked.segment.idc::after {
  display: none;
}
button.ui.button.limpiar-icono,
button.ui.button.limpiar-icono i.redo.icon {
  margin: 0 !important;
  padding: 0 !important;
}

/*BOTON AGREGAR GRID*/
.ui.button {
  background-color: #2f62fb !important;
  color: #fff;
  border-radius: 0 !important;
}

/* .ui.button i {
  background-color: rgba(0, 0, 0, 0.2) !important;
} */

.ui.button.btnCerrar {
  background-color: #e9ecf5 !important;
  color: black !important;
}

/*TABLA GRID*/
.ui.table thead th {
  background: #1c43b8 !important;
  padding: 0.92857143vw 0.78571429vw !important;
  color: #fff !important;
  text-transform: uppercase !important;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) thead {
    display: contents !important;
  }
  .ui.table:not(.unstackable) tbody.cuerpoRV,
  .ui.table:not(.unstackable) tr.filaRV,
  .ui.table:not(.unstackable) tr.filaRV > td,
  .ui.table:not(.unstackable) tr.filaRV > th.headerRV {
    width: initial !important;
    display: revert !important;
  }
  .ui.table:not(.unstackable) tr.filaRV > td,
  .ui.table:not(.unstackable) tr.filaRV > th.headerRV {
    width: initial !important;
    display: revert !important;
  }
  div#iframeid.ui.container {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .ui.container.todoContenido {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}

.ui.table tbody tr:nth-child(odd) {
  background-color: #e9ecf5;
}

.ui.table tbody tr i.icon {
  color: #1c43b8 !important;
  font-size: 1.1vw !important;
  margin-right: 2%;
  margin-left: 2%;
  margin-top: 4%;
}
.ui.table tr td:last-child {
  text-align: center;
}
.ui.table td {
  padding: 0.78571429vw 0.78571429vw !important;
}
/*
.ui.table tbody tr:hover i.icon {
  display: inline;
}
*/

.imagen_perfil_grid {
  width: 4vw;
  height: 4vw;
  float: left;
  border-radius: 4vw;
  background-size: cover;
}

.fullname_user_grid {
  margin: 17px 10px 0px 15px !important;
  float: left;
}

.image_detalle {
  width: 160px;
  height: 160px;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
}

.info_materia_container > label {
  width: 100%;
  float: left;
}

.img_perfil_header {
  width: 3vw;
  border: 2px solid white;
  /* background-image:url("../images/default_user.png") !important; ; */
  background-size: cover;
  border-radius: 100px;
  height: 3vw;
  margin-left: 8px;
}

.ui.dropdown > .text {
  font-size: 1vw;
  font-weight: 400;
  /* font-family: 'quicksandlight'!important; */
}

.ui.container.logoContainer {
  width: 50% !important;
}

.ui.container.opcionesMenu {
  border-radius: 2vw;
  background-color: white !important;
  justify-content: space-evenly;
  position: fixed;
  z-index: 100 !important;
  box-shadow: 2px 2px 9px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 7.5vw;
  width: 100% !important;
  padding-left: 4%;
  padding-right: 4%;
}

.ui.item.simple.dropdown {
  /* margin-top: 5vw; */
  margin-right: 2%;
  display: flex;
  align-items: center;
}

.ui.dropdown > .dropdown.icon {
  font-size: 1.3vw !important;
  margin-left: 0.3vw !important;
}

.ui.simple.active.dropdown > .menu,
.ui.simple.dropdown:hover > .menu {
  width: 100% !important;
  top: 100% !important;
}

.inline.fields.checkbox {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.subtituloDetalle {
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: center;
}
.lineaTitulosDetalle {
  height: 21px;
  background: linear-gradient(to left, #4e7aff, #1c43b8);
  /* margin-top: 0.6%; */
}
.titulosDetalle {
  font-weight: 600;
  font-size: 1.2em;
  width: fit-content;
  text-transform: uppercase;
  background-color: white;
}
.ui.label {
  font-weight: normal !important;
}

i.edit.icon.editarFotoPerfil {
  position: absolute;
  width: 2vw;
  height: 2vw;
  left: 19.5vw;
  border-radius: 10vw;
  cursor: pointer;
  top: 18.5vw;
  background-color: white;
  padding: 2%;
}
i.edit.icon.editarFotoPerfil:hover {
  background-color: #55fade;
}
i.save.icon.guardarFotoPerfil {
  position: absolute;
  width: 2vw;
  height: 2vw;
  left: 2vw;
  border-radius: 10vw;
  cursor: pointer;
  top: 18.5vw;
  background-color: white;
  padding: 2%;
}
i.save.icon.guardarFotoPerfil:hover {
  background-color: #55fade;
}
.ui.card.editarPerfil {
  cursor: pointer;
  font-weight: 700;
  font-size: 1.3em;
}
.ui.card.editarPerfil:hover {
  color: #55fade;
}

.ui.card.editarPerfil .content {
  padding: 0;
  align-self: center;
  display: grid;
  align-content: center;
}
.contenedorPerfil .ui.card {
  box-shadow: none !important;
}
.contenedorPerfil input {
  border: none !important;
  padding: 0 !important;
}
.contenedorPerfil .ui.input {
  width: 40% !important;
}
.contenedorPerfil .sixteen.wide.column {
  padding-bottom: 0% !important;
}
.contenedorPerfil .eye:hover {
  color: #55fade;
}
.swal2-styled.swal2-confirm {
  background-color: #55fade !important;
}
.info_principal_detail .label,
.adocional_info_detail .label {
  width: 100% !important;
  background-color: #fff !important;
  border-left: 5px solid#1C43B8 !important;
  border-radius: 0 !important;
  margin: 0 !important;
  font-size: 20px !important;
}
.ui.label.subtituloAcceso {
  font-weight: 700 !important;
  color: black;
}

.ui.form .field > .selection.dropdown > .dropdown.icon {
  background-color: transparent !important;
}
.ui.dropdown > .text {
  white-space: nowrap;
}
.descripcion-geocerca i ~ label {
  font-size: 16px !important;
}
.col_custom_form > .field {
  margin-bottom: 1vw !important;
}
.ui.form .field {
  margin: 0vw !important;
  margin-bottom: 1vw !important;
}

.ui.inverted.dimmer > .content > * {
  width: 10vw;
  /* position: relative; */
}

.container_custom_loader {
  position: fixed;
  text-align: center;
  z-index: 9999;
  background-color: #eeeeee;
  top: 0;
  width: 100%;
  left: 0;
  height: 100vh;
}

#custom_slider {
  position: fixed;
  background-size: cover;
  overflow-y: scroll;
  padding: 15px;
  background-color: white;
}

#custom_slider,
.custom_overlay {
  top: 60px;
  height: 93.8vh;
  right: 0;
  z-index: 9;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.custom_overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  position: fixed;
  left: 0px;
}

.container_img {
  text-align: center;
  width: 100%;
}

.container_doc {
  text-align: center;
  width: 80%;
  margin-top: 13px;
  position: absolute;
}

.ui.red.icon.button {
  background-color: #db2828 !important;
}

.ui.red.button:hover {
  background-color: #d01919 !important;
}

.container_image_preview {
  display: inline-block;
}

/* .container_image_preview > video {
  margin-top: 60px;
} */

.button-adjuntar > .ui.teal.button {
  background-color: #00b5ad !important;
}
.button-adjuntar > .ui.teal.button:hover {
  background-color: #009c95 !important;
}

#img_user {
  width: 20vw;
  height: 20vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: -webkit-center;
}
#img_user2 {
  width: 21vw;
  height: 21vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: -webkit-center;
}

#img_detail {
  width: 120px;
  height: 120px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.ui.cards {
  justify-content: center;
}
.ui.card.card-planes {
  width: 21vw;
  border-radius: 1vw !important;
}
.ui.card > .image,
.ui.cards > .card > .image {
  background-color: #55fade !important;
}
.ui.card > .image > img,
.ui.cards > .card > .image > img {
  height: 30vw !important;
  place-content: space-between !important;
}
.ui.card.profile {
  max-width: 102% !important;
}
.ui.button i.volver {
  background-color: transparent !important;
  font-size: 1.5vw !important;
}
button.ui.button.volver {
  color: black !important;
  margin-bottom: 1vw;
  font-size: 1.2vw !important;
  background-color: transparent !important;
  padding: 1% 0%;
  transition: opacity 0.2s, color 0.2s, transform 0.2s;
}
button.ui.button.volver:hover {
  font-family: "GothamPro" !important;
  color: #1c43b8 !important;
  background-color: transparent !important;
  transform: scale(1.3);
}

.ui.pagination.menu {
  margin-bottom: 12% !important;
}
.contenedor-nombre-plan {
  /* background-color: rgb(12, 23, 61); */
  color: white;
  height: 2.5vw;
  border-radius: 0.5vw;
}
h3.nombre-plan {
  font-size: 1.5vw;
  text-align: center;
  padding-top: 0.3vw;
  width: 100%;
  margin: 0vw !important;
  text-overflow: ellipsis;
}
h3.nombre-plan2 {
  font-size: 1vw;
  font-family: "GothamPro" !important;
  text-transform: uppercase;
  text-align: initial;
  color: white;
  padding-top: 0.3vw;
  width: 100%;
  margin: 0vw !important;
  text-overflow: ellipsis;
}
.content.tarjetasVentas {
  background: linear-gradient(to left top, #4778fd, #0744f8) !important;
}
.ui.dropdown .menu > .item.menuItem {
  padding: 0.78571429vw 1.14285714vw !important;
  font-size: 0.85vw !important;
}
.ui.checkbox .box:before,
.ui.checkbox label:before {
  width: 1.2vw !important;
  height: 1.2vw !important;
}
.ui.card > .content,
.ui.cards > .card > .content {
  padding: 1vw 1vw !important;
}
.description.descripcion-geocerca {
  font-size: 1vw;
}
.modal i.icon,
.modal-es i.icon {
  font-size: 1.5vw !important;
}
.modal i.remove.icon {
  font-size: 1vw !important;
  display: flex !important;
  align-items: center !important;
}
.modal .header h2 {
  font-size: 2vw;
  font-family: "GothamPro" !important;
}
.ui.form .field > label {
  font-size: 1vw !important;
}
button.ui.icon.left.labeled.button.agregar-imagen,
button.ui.icon.right.labeled.button.agregar-imagen {
  font-size: 0.8vw !important;
}
button.ui.icon.left.labeled.button.agregar-imagen .i.upload.icon,
button.ui.icon.right.labeled.button.agregar-imagen .i.upload.icon {
  font-size: 1vw !important;
}
/* mapa cursor */
.gm-style > div:nth-child(1) {
  cursor: default !important;
}

button.ui.secondary.button.search_button_swipe {
  background-color: #55fade !important;
  color: #000000;
  font-family: "GothamPro" !important;
}

/* calendario */
.ui.styled.accordion {
  width: 100% !important;
  border-radius: 0% !important;
  box-shadow: none !important;
}
.accordion.ui.styled.menu-calendario .active.title {
  background-color: #1c43b8 !important;
  border-top-left-radius: 1vw;
  height: 2.3vw;
  /* border-top-right-radius: 1vw; */
  color: white;
  transition: 0.5s;
}
.accordion.ui.styled.menu-calendario-contenido .title {
  font-family: "gotham_prolight";
  color: #1c43b8 !important;
  background-color: #cfdafc !important;
  text-align: center;
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
}
.accordion.ui.styled.menu-calendario-contenido .active.title {
  display: none;
}

.accordion.ui.styled.menu-calendario {
  border-top-left-radius: 1vw !important;
  /* border-top-right-radius: 1vw !important; */
}
.ui.styled.accordion .content.active {
  padding: 0% !important;
}

.content.active .accordion {
  box-shadow: none !important;
  background-color: #cfdafc !important;
}
.accordion.ui.styled.menu-calendario-contenido
  .ui.styled.accordion
  .content.active {
  padding: 0% !important;
}
.accordion.ui.styled.menu-calendario .content.active .active.title {
  border-top-left-radius: 0vw !important;
  border-top-right-radius: 0vw !important;
  background-color: #2f62fb !important;
  color: white;
}
.accordion.ui.styled.menu-calendario-contenido .content.active .accordion {
  box-shadow: none !important;
}
.accordion.ui.styled.menu-calendario .content.active .title {
  padding-left: 2vw;
  height: 2.7vw;
  white-space: pre;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.ui.styled.accordion .accordion .content {
  padding: 0 !important;
}
.row-empleado2 .accordion {
  margin: 0 !important;
}
.contenedor-detalleGeocerca {
  background-color: white;
  width: 16vw;
  position: absolute;
  right: -5vw;
  top: -1vw;
  box-shadow: 2px 2px 9px 1px rgb(0 0 0 / 20%);
  border-top-left-radius: 2vw;
  border-bottom-left-radius: 2vw;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}
button.ui.button.btn-calendar i.icon {
  background-color: transparent !important;
  font-size: 1.5vw !important;
}
.cell-fecha-header {
  height: 3.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cell-fecha-header2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-fecha-header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 3.8vw;
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  justify-content: center;
  align-items: stretch;
  width: 100% !important;
  background-color: #cfdafc;
  text-align: center;
  /* padding-top: 0.75vw;
  padding-bottom: 0.75vw; */
}
.row-fecha-header2 {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 3.8vw;
  flex-wrap: wrap;
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  justify-content: center;
  align-items: stretch;
  width: 100% !important;
  background-color: #cfdafc;
  text-align: center;
}
.bolita-calendario:hover {
  opacity: 0.5;
  transition: 0.3s;
  cursor: pointer;
}
.bolita-calendario {
  animation-duration: 2s;
  animation-name: slidein;
  /* animation-iteration-count: infinite; */
}
@keyframes slidein {
  from {
    margin-left: 3000%;
    width: 30%;
  }

  to {
    margin-left: 0%;
    width: 75%;
  }
}
.menu-calendario-contenido
  > .content.active
  > div
  > .accordion
  > .content.active
  > div
  > .accordion
  > .content {
  padding: 0.6em !important;
}

.img-perfil-calendario {
  opacity: 0;
}

.ui.button.btn-calendar {
  position: absolute;
  height: 100%;
  width: 3%;
  background-color: #cfdafc !important;
  color: #000000 !important;
  margin: 0px !important;
  padding: 5px !important;
  transition: 0.3s;
}

.ui.button.btn-calendar:hover {
  color: #1c43b8 !important;
  transition: 0.3s;
}

.modal-calendar span {
  font-weight: bold;
}

.modal-calendar label {
  font-weight: bolder;
}

.filtro-fecha ~ .ui.button {
  padding: 0px 15px !important;
  height: 38px !important;
  margin-top: 17px !important;
}

.filtro-fecha .ui.label.label {
  font-size: 1vw !important;
  background-color: #f6f3fd !important;
  font-weight: bold !important;
}

.row-empleado {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 3.8vw;
  justify-content: inherit;
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  align-items: stretch;
  width: 100% !important;
  background-color: #fff;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 2vw;
}
.row-empleado-vacio {
  font-style: italic;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.8vw;
  justify-content: inherit;
  align-items: stretch;
  width: 100% !important;
  background-color: #fff;
  padding: 0.5em;
  padding-left: 3vw;
}

.row-empleado img {
  object-fit: cover;
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 5vw;
}

.row-empleado label.rol {
  font-size: 12;
  color: #adadad;
}

.dia-actual {
  background-color: #2f62fb;
  height: 100%;
  color: black;
  padding: 0.3vw 1vw;
}
.ui.indicating.progress[data-percent^="1"] .bar,
.ui.indicating.progress[data-percent^="2"] .bar,
.ui.indicating.progress[data-percent^="3"] .bar,
.ui.indicating.progress[data-percent^="4"] .bar {
  background-color: rgb(42, 148, 42) !important;
  height: 1vw !important;
}
.ui.indicating.progress[data-percent^="5"] .bar,
.ui.indicating.progress[data-percent^="6"] .bar,
.ui.indicating.progress[data-percent^="7"] .bar {
  background-color: rgb(223, 202, 14) !important;
  height: 1vw !important;
}
.ui.indicating.progress[data-percent^="8"] .bar,
.ui.indicating.progress[data-percent^="9"] .bar,
.ui.indicating.progress[data-percent^="10"] .bar {
  background-color: rgb(184, 27, 27) !important;
  height: 1vw !important;
}

.accordion.ui.styled.menu-calendario-contenido .title,
.accordion.ui.styled.menu-calendario .title {
  height: 3.2vw !important;
  font-size: 1.3vw !important;
  overflow: hidden;
}
.ui.horizontal.label,
.ui.horizontal.labels .label {
  width: 0vw !important;
  border-radius: 3vw;
  min-width: 0vw !important;
  height: 1vw !important;
}
.menu-calendario-contenido i {
  opacity: 0 !important;
}

.ui.cards > .card.card-planes:hover {
  margin-top: 3vw;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ui.cards > .card.card-planes {
  margin-top: 4vw;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.ui.card > .extra a:not(.ui):hover,
.ui.cards > .card > .extra a:not(.ui) {
  font-family: "gotham_prolight" !important;
}

.ui.card > .extra a:not(.ui):hover,
.ui.cards > .card > .extra a:not(.ui):hover {
  font-family: "gotham_prolight" !important;
  color: #55fade !important;
  font-weight: 500;
}
.ui.form .field .prompt.label .item {
  font-size: 0.8vw;
}
.icon.icon-plan {
  font-size: 2vw !important;
}
.linea-plan {
  margin: 1vw 0vw;
  border-bottom: 0.5vw solid #55fade;
}
i.tarjeta-plan {
  font-size: 1.5vw !important;
}
.ui.grid > .row {
  padding-top: 1vw !important;
  padding-bottom: 1vw !important;
}
.ui.grid > .row.planes-wizard-ilimitados {
  padding-top: 0% !important;
}
.ui.grid > .row.contenedor-dona-finanzas {
  padding: 0% !important;
}
.row.contenedor-dona-finanzas input.input-date {
  width: 13vw;
}
.ui.grid > .row > .column .item:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.whitout-hover,
.ui.grid > .row > .column .ui.list.whitout-hover .item:hover {
  opacity: 1 !important;
}
.ui.grid > .row.row-calendario1 {
  padding-bottom: 0 !important;
  margin-top: 2vw;
}
.ui.grid > .row.row-calendario3 {
  padding-bottom: 0 !important;
  margin-top: 0vw;
}
.ui.grid > .row.row-calendario2 {
  padding-top: 0 !important;
}
.tarjeta-tabla-usuarios {
  margin-top: 2vw;
  font-size: 1vw !important;
}
.icono-tabla-ventas:hover {
  color: #55fade;
  transition: 0.3s;
}
.column.card-planes-nombres {
  padding-left: 7vw !important;
}
.ui.selection.dropdown.card-planMes {
  position: absolute;
  left: 70vw;
  bottom: 0vw;
}
.contenedorMenuu {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1vw;
  transition: 0.3s;
}

.rdrCalendarWrapper {
  font-size: 1vw !important;
  width: 100% !important;
}

.rdrMonth {
  width: 100% !important;
}
div#chart_div_raw text {
  font-family: "GothamPro";
  font-size: 0.8vw !important;
}
.contenedorMenuu > i {
  margin-bottom: 0.5vw !important;
}
.contenedorMenuu.aqui {
  font-family: "GothamPro";
  border-top: solid 3px #1c43b8;
  transition: 0.3s;
  color: #1c43b8;
}
.ui.item.simple.dropdown:hover {
  font-family: "GothamPro";
  border-top: solid 3px #1c43b8;
  transition: 0.3s;
  color: #1c43b8;
  padding: 0.5%;
}
.ui.dropdown .menu > .item:hover {
  border-left: solid 5px #55fade;
}
.ui.icon.button {
  color: white;
}
.color-izq {
  animation: 8s ease 0s infinite normal both running bg-pan-right;
}
.color-grad {
  background-image: linear-gradient(
    270deg,
    #0737c7,
    #2f62fb,
    #537dfc
  ) !important;
  background-size: 600% 100%;
}
@-webkit-keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.labelGeocerca {
  cursor: pointer;
}
.labelGeocerca:hover {
  color: orange;
}
tr.row-withHover:hover {
  cursor: pointer;
  background-color: #2f62fb80 !important;
  transition: 0.5s;
}

@media screen and (max-width: 425px) {
  .column.gridIzq {
    width: 100% !important;
    height: 20vh;
  }
  .column.gridDer {
    width: 100% !important;
    height: 84vh;
  }
  .logo_login {
    padding: 7vw 24vw 0vw 26vw;
  }
  .ui.stacked.segment.login {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  h1.ui.header {
    font-size: 4vw !important;
    padding-right: 10vw !important;
    padding-left: 10vw !important;
    margin-top: 7vw !important;
    margin-bottom: 1vw !important;
  }
  .ui.buttons.login button.ui.button.hvr-shutter-in-vertical {
    height: 3vw;
  }
  .container_bottom_buttons {
    padding-top: 0vw;
    padding-bottom: 2vw;
  }
  i.edit.icon.orangeHover {
    cursor: pointer;
  }
}
i.edit.icon.orangeHover {
  cursor: pointer;
}
i.edit.icon.orangeHover:hover {
  transition: 0.3s;
  color: #2f62fb;
}
.ui.menu .item {
  padding: 0.92857143vw 1.14285714vw !important;
}
.ui.menu {
  font-size: 1vw !important;
  font-family: "GothamPro" !important;
}
.triangulo-inferior {
  width: 0;
  height: 0;
  border-left: 1vw solid #000000;
  border-top: 1vw solid transparent;
  border-bottom: 1vw solid transparent;
  position: absolute;
  align-self: flex-end;
  margin-left: -3.5vw !important;
  margin: -0.6vw;
  transform: rotate(135deg);
}

.triangulo-superior {
  width: 0;
  height: 0;
  border-left: 1vw solid #000000;
  border-top: 1vw solid transparent;
  border-bottom: 1vw solid transparent;
  position: absolute;
  align-self: flex-start;
  margin-right: -3vw;
  margin-top: -0.7vw;
  transform: rotate(315deg);
}

.bitacora .title {
  font-size: 1.5vw !important;
}
.bitacora2 .title {
  font-size: 1.2vw !important;
}
.accordion.accordionGeocercas2,
.accordion.accordionGeocercas2 .accordion {
  margin: 0 !important;
}
.accordion.accordionGeocercas2 .accordion .title {
  font-size: 0.8vw !important;
  padding-left: 3.5vw !important;
  background-color: white;
}
.ui.styled.accordion.bitacora2
  .accordion.accordionGeocercas2
  .accordion
  .active.title {
  font-size: 0.8vw !important;
  padding-left: 3.5vw !important;
  background-color: #5f87ff !important;
}
.accordion.accordionGeocercas2 .accordion .title:hover {
  background-color: white !important;
}
.accordion.accordionGeocercas2 .title {
  font-size: 1vw !important;
  padding-left: 2.5vw !important;
}

.ui.styled.accordion.bitacora {
  background-color: transparent !important;
}
.bitacora .active.title {
  background-color: #1c43b8 !important;
  font-size: 1.5vw !important;
  color: white !important;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}
.bitacora2 .active.title {
  background-color: #1c43b8 !important;
  color: white !important;
  border-top-left-radius: 1vw;
}
img.image-bitacora {
  object-fit: cover;
}
.ui.styled.accordion.bitacora .accordionGeocercas .active.title {
  background-color: #2f62fb !important;
  text-align: center !important;
  font-size: 2vw !important;
  color: white !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top-left-radius: 0vw;
  border-top-right-radius: 0vw;
}
.ui.styled.accordion.bitacora2 .accordionGeocercas2 .active.title {
  background-color: #2f62fb !important;
  color: white !important;
  border-top-left-radius: 0vw;
  border-top-right-radius: 0vw;
}

.accordionGeocercas .accordion.ui {
  background-color: #2f62fb !important;
  border-radius: 0 !important;
}
img.ui.image.img-perfil-subcontratos {
  width: 10vw;
  height: 10vw;
  object-fit: cover;
  border-radius: 20vw;
  border: 0.5vw solid #55fade;
}
.dateRango {
  display: initial !important;
}
.cardImage {
  text-align: center;
  align-self: center;
}

.infoButton {
  border-radius: 40px !important;
  margin-left: 15px;
}
.kwUGKV,
.glqWOv {
  width: 3.5vw !important;
  min-width: 3.5vw !important;
  font-size: 1.5vw !important;
  height: 3.5vw !important;
  line-height: 0% !important;
}
.glqWOv:hover:enabled,
.glqWOv:focus:enabled {
  color: white !important;
  background-color: #55fade !important;
}

button.ui.button.estatusFinish {
  font-family: "quicksandlight" !important;
  color: rgb(105 181 29);
  background-color: #c4f4cc !important;
  border-radius: 2em !important;
  font-weight: bold !important;
  height: 2.5vw;
  font-size: 1vw !important;
  width: 9vw;
}
button.ui.button.estatusNoIniciado {
  font-family: "quicksandlight" !important;
  color: rgb(17 162 206);
  background-color: #acdcf4 !important;
  border-radius: 2em !important;
  font-weight: bold !important;
  height: initial;
  font-size: 1vw !important;
  width: 9vw;
}
button.ui.icon.right.labeled.button.hvr-sweep-to-right {
  height: 2.5vw;
}
button.ui.icon.right.labeled.button.hvr-sweep-to-right.btnPrenomina:hover {
  font-size: 0.9vw !important;
}

.ui.grid .row .ui.label.label-empleado {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1vw !important;
  font-weight: 700 !important;
  text-transform: none;
  background-color: #f6f3fd;
  padding: 0 !important;
}

.ui.grid > .row.row-sinPadding {
  padding: 0% !important;
}
.css-qfjvwx-control {
  font-size: 1vw !important;
  font-family: "quicksandlight";
  height: initial !important;
}
.css-14jk2my-container {
  font-size: 1vw !important;
  font-family: "quicksandlight";
}
.css-11l6d5b-ValueContainer {
  font-size: inherit !important;
}
.ui.modal > .header {
  font-family: "GothamPro" !important;
}
img.ui.image.img-detalleCalendar {
  width: 8vw;
  height: 8vw;
  border-radius: 5vw;
  margin-right: 1vw;
  margin-left: 1vw;
  object-fit: cover;
}
.ui.fluid.multiple.search.selection.dropdown.fitHeight {
  height: fit-content;
}
.ui.horizontal.list.administradorGeo {
  margin-left: -18px !important;
}

i.user.circle.outline.massive.icon.icon-sesion-activa {
  font-size: 1.7vw !important;
}

button.ui.button.letraNegra {
  color: white;
}
.GothamPro {
  font-family: GothamPro !important;
}
.ui.card.card-wizard-detalle {
  width: 100% !important;
  box-shadow: none;
}

.ui.grid > .row > .column .item.tipoUsuario:hover {
  opacity: 1;
}

.ui.grid.botones-wizardM {
  margin-bottom: 0vw !important;
  margin-top: 1vw !important;
}
button.ui.primary.button.boton-wizardM {
  border-radius: 4vw !important;
  color: #55fade;
  font-family: "gotham_prolight" !important;
  padding-right: 3vw !important;
  padding-left: 3vw !important;
}
.equal.width.fields.noMargin {
  margin-bottom: 1vw !important;
}
.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  width: 1.5vw !important;
  height: 1.5vw !important;
  top: -0.1vw !important;
  left: -0.15vw !important;
  background-color: #2f62fb !important;
}
/* @media screen and (max-width: 672px) {
  div#root {
    background-color: #009c95;
  }
} */
/*Graficas*/

.graphic div {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphic .btn-graphic,
.ui.button.btn-graphic-clean {
  background-color: #fff !important;
  padding: 0 !important;
  font-size: 20px !important;
  width: 45%;
  height: 50px;
  margin: 5px;
}

.graphic .btn-graphic:hover {
  background-color: #fff !important;
}

.fitHeight.filtro-geocerca .label {
  border-radius: 30px;
  background-color: #d3defd;
  color: #000000;
  font-weight: bolder !important;
}

.tabla-prestamos tbody tr td {
  text-align: left !important;
}

.tabla-prestamos tbody tr td:nth-child(n + 2) {
  width: 20%;
}

button.ui.button.btnPay:hover {
  background-color: #2f62fb !important;
  transition: 0.3s;
  color: white !important;
}
button.ui.button.btnPay {
  font-family: "GothamPro" !important;
  color: #2f62fb !important;
  background-color: white !important;
  border: 0.3vw solid #2f62fb;
  border-radius: 2vw !important;
  font-size: 1vw !important;
  transition: 0.3s;
}
.css-1wa3eu0-placeholder {
  color: lightgray !important;
  font-family: "quicksandlight" !important;
  font-size: 1vw !important;
  top: 1.2vw !important;
}
.ui.negative.message {
  padding: 0.5vw !important;
  display: flex !important;
  align-items: center !important;
}

.ui.negative.message .content {
  font-size: 0.9vw !important;
}

.ui.negative.message .item {
  display: flex !important;
  align-items: center !important;
}
.error.field .ui.pointing.label,
.ui[class*="pointing above"].label {
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
  background-color: #fff6f6 !important;
  color: #9f3a38 !important;
  font-weight: 600 !important;
  margin-top: 0.3vw !important;
}
.error.field .ui.pointing.label .item,
.ui[class*="pointing above"].label .item {
  display: flex !important;
  align-items: center !important;
}
.error.field .ui.pointing.label:before,
.error.field .ui[class*="pointing above"].label:before {
  display: none !important;
}
.ui.grid.grid_dona {
  margin-top: 1vw !important;
}

.ui.white.label.numeroPlan {
  background-color: white;
  font-family: "GothamPro";
  color: #2f62fb;
}
.ui.green.label.numeroPlan {
  font-family: "GothamPro";
}

.carrusel-caracteristicas .geTNrw {
  height: 63vw !important;
}
.ui.scrolling.modal.transition.visible.active.ModalDetallePlan .content {
  min-height: 45vw;
}

.inputDateClean input#buscador {
  padding-right: 0.5vw !important;
}
